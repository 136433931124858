import React from 'react';
import { Link, graphql } from 'gatsby';
import classNames from 'classnames';
import { injectIntl } from 'gatsby-plugin-intl';
import pages from '../../../static-pages';
import lifeRig from '../../assets/images/life-ring.png';
import './info.scss';
import { Helmet } from 'react-helmet';
import PageTopMenu from '../PageTopMenu';

const menuPages = pages.map((element) => ({
  label: element.title,
  url: element.pagePath,
}));
const InfoComponent = ({ data, intl: { formatMessage, locale } }) => {
  const pageData = data.allSitePage.edges[0].node.context;
  const currentLocale = locale === 'en' ? '': `/${locale}`
  return (
    <div className="info-component-wrapper">
      <Helmet>
        <title>
          Lotteroo | {formatMessage({ id: pageData.title })}
        </title>
      </Helmet>
      <div className="top-block-profile">
        <div className="title"> {formatMessage({ id: pageData.title})}</div>
      </div>
      <PageTopMenu pages={menuPages} currentPage={pageData.pagePath} locale={currentLocale}/>
      <div className="page-content" style={{
        paddingTop: pageData.contentMenu === 'Privacy Policy'? 15: 0
      }}>
        <div className="text" dangerouslySetInnerHTML={{ __html: formatMessage({ id: pageData.key }) }} />
        <div className="nav-problem-wrap">
          <div className="nav">
            <ul>
              {pages.map((element) => (
                <li className="link-item">
                  <Link
                    className={classNames('link', {
                      active: pageData.contentMenu === element.contentMenu,
                    })}
                    to={`${currentLocale}${element.pagePath}`}
                  >
                    {formatMessage({ id: element.contentMenu })}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="having-problem">
            <div className="img">
              <img src={lifeRig} alt="Having problems image?" />
            </div>
            <div className="title">
              {formatMessage({ id: 'Having problems?' })}
            </div>
            <div className="text">
              {formatMessage({ id: 'Having problems text' })}
            </div>
            <div className="button">
              <button className="blue-btn">{formatMessage({ id: 'Get help now' })}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: {path: {eq: $path}}){
      edges {
        node {
          context {
            pagePath,
            title,
            contentMenu,
            key
          }
        }
      }
    }
  }
`;
export default injectIntl(InfoComponent);

import React, {useState, memo, useRef} from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import style from './top-menu.module.scss';
import {useOutsideHandler} from "../../hooks/useOutsideHandler"
import {injectIntl} from 'gatsby-plugin-intl'


/**
 * SIMPLE MENU
 * @param pages
 * @param currentPage
 * @constructor
 */
const PageTopMenu = ({ pages, currentPage, intl: {formatMessage, locale} }) => {
  const [showDesktopLinks, setShowDesktopLinks] = useState(false);
  const menuRef = useRef(null);
  useOutsideHandler(menuRef, (clicked) => {
    if(clicked) {
      setShowDesktopLinks(false);
    }
  })
  const handleDesktopLinks = () => {
    setShowDesktopLinks(!showDesktopLinks);
  }
  const disableDesktopLinks = () => {
    setShowDesktopLinks(false);
  }
  const currentLocale = locale === 'en' ? '': `/${locale}`
  const currentPageLabel = pages.find((element) => element.url === currentPage);
  return(
  <div className={classNames("page-menu", style.pageMenu)}>
    <div className={classNames(style.linksWrapper, 'links-wrapper')} ref={menuRef}>
      <div className={classNames(style.mobileLinks, 'mobile-links')} onClick={handleDesktopLinks}>
        {currentPageLabel ? formatMessage({id: currentPageLabel?.label}): null}
      </div>
      <ul className={classNames(style.desktopLinks, {
        [style.activeDesktop]: showDesktopLinks
      })}>
        {pages.map((element, index) => (
          <li
            key={ element.url}
            className={classNames({
            [style.active]: element.url === currentPage,
          })}
          >
            <div>
              <Link to={currentLocale + element.url} onClick={disableDesktopLinks}>{formatMessage({
                id: element.label
              })}</Link>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </div>
)};

export default memo(injectIntl(PageTopMenu));
